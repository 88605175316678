<template>
    <div>
        <div class="header" v-if="!isPopup">
            <span @click="gouot"></span>
            <span>{{title}}</span>
            <span @click="islogout">退出</span>
        </div>

        <div class="header" v-if="isPopup">
            <span @click="closePopup"></span>
            <span>{{title}}</span>
            <span @click="closePopup">关闭</span>
        </div>
    </div>
    

</template>

<script>
    export default {
        data() {
            return {

            }
        },
        props:['title','out','isPopup'],
        methods: {
            closePopup(){
                this.$emit('closePopup')
            },
            gouot(){
                this.$router.push('/'+this.out)
            }
        },
    }
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    justify-content: space-between;
    
    span {
        display: inline-block;
    }
    span:nth-child(1) {
        width: 30px;
        height: 30px;
        background-image: url(../assets/u39.png);
        background-size: 100%;
        margin-top: 8px;
    }
}
</style>